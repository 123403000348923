import React from "react";
import "./ConfirmationModal.css"; // Create a CSS file for styling

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <div className={`confirmation-modal ${isOpen ? "open" : "closed"}`}>
      <div className="modal-content">
        <p>Are you sure you want to delete this post?</p>
        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={onConfirm}>Delete</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
