import React, { useEffect, useState } from "react";
import { firestore, collection, getDocs } from "../components/utils/Firebase";
import AdminChatComponent from "./AdminChat";

const UserChatContainer = () => {
  const [userIds, setUserIds] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedChatId, setSelectedChatId] = useState("");
  const [chatIds, setChatIds] = useState([]);
  const [isUserListVisible, setIsUserListVisible] = useState(false);

  useEffect(() => {
    // Fetch user IDs from your Firestore collection (adjust the path accordingly)
    const fetchUserIds = async () => {
      try {
        const userCollection = collection(firestore, "users");
        const userSnapshot = await getDocs(userCollection);
        const usersData = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          username: doc.data().username,
        }));
        setUserIds(usersData);
      } catch (error) {
        console.error("Error fetching user IDs:", error);
      }
    };

    fetchUserIds();
  }, []);

  useEffect(() => {
    // Update selectedChatId when selectedUserId changes
    if (selectedUserId) {
      const adminId = "wx7ZE1RGKHfVr9kDEmESupIGgQx1"; // Replace with your actual admin ID
      const generatedChatId = generateChatId(selectedUserId, adminId);
      setSelectedChatId(generatedChatId);
    } else {
      setSelectedChatId(""); // Clear selectedChatId if no user is selected
    }
  }, [selectedUserId]);

  return (
    <div
      style={{
        background: "black",
        padding: "10px",
        textAlign: "center",
      }}
    >
      <label htmlFor="userSelect">Select a user:</label>
      <select
        id="userSelect"
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
      >
        <option value="">Select User</option>
        {userIds.map((user) => (
          <option key={user.id} value={user.id}>
            {user.username}
          </option>
        ))}
      </select>

      {selectedUserId && (
        <div>
          <AdminChatComponent isAdmin={true} chatId={selectedChatId} />
        </div>
      )}
    </div>
  );
};

// Helper function to generate a unique chat ID
const generateChatId = (userId, adminId) => {
  const sortedIds = [userId, adminId].sort();
  return `${sortedIds[0]}_${sortedIds[1]}`;
};

export default UserChatContainer;
