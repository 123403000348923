// UserProfile.jsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, firestore } from "../components/utils/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  FiLoader,
  FiTwitter,
  FiFacebook,
  FiInstagram,
  FiLinkedin,
} from "react-icons/fi"; // Import social media icons

import "./UserProfile.css";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [logoutMessage, setLogoutMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mediaType, setMediaType] = useState("image");
  const [imagePosts, setImagePosts] = useState([]);
  const [videoPosts, setVideoPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);

        const userDocRef = doc(firestore, "users", authUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          setProfileData(userDocSnapshot.data());
        }

        // Fetch posts created by the user
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", authUser.uid)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const userPosts = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(userPosts);
        // Separate posts into images and videos
        const imagePosts = userPosts.filter(
          (post) => post.mediaType === "image"
        );
        const videoPosts = userPosts.filter(
          (post) => post.mediaType === "video"
        );

        setImagePosts(imagePosts);
        setVideoPosts(videoPosts);

        setLoading(false);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const UpdateButton = () => (
    <Link to="/Update-profile" className="login-link">
      <button>Update</button>
    </Link>
  );

  const toggleMediaType = () => {
    setMediaType((prevType) => (prevType === "image" ? "video" : "image"));
  };

  return (
    <div className="user-profile-container">
      {loading ? (
        <FiLoader className="loader" />
      ) : user ? (
        <>
          {" "}
          <div className="profile-card">
            <div className="profile-card-img">
              <img src={profileData.image} alt={profileData.username} />
            </div>
            <div className="profile-card-details">
              <h2>{profileData.username}</h2>
              <p>Email: {user.email}</p>
              <p>Bio: {profileData.bio}</p>
              <p>Specalty: {profileData.specialty}</p>
              <div className="social-media-icons">
                {profileData.twitter && (
                  <a
                    href={profileData.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiTwitter />
                  </a>
                )}
                {profileData.facebook && (
                  <a
                    href={profileData.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiFacebook />
                  </a>
                )}
                {profileData.instagram && (
                  <a
                    href={profileData.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiInstagram />
                  </a>
                )}
                {profileData.linkedin && (
                  <a
                    href={profileData.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiLinkedin />
                  </a>
                )}
              </div>
              <UpdateButton />
            </div>
          </div>
          {posts.length > 0 ? (
            <div>
              <h3>My works</h3>

              <ul>
                <button onClick={toggleMediaType}>
                  Toggle {mediaType === "image" ? "Video" : "Image"}
                </button>
                <div className="user-posts-grid">
                  {posts.map((post) => (
                    <div key={post.id} className="user-post-item">
                      {post.mediaType === "image" ? (
                        <img className="media" src={post.media} alt="Post" />
                      ) : post.mediaType === "video" ? (
                        <video className="media" controls>
                          <source src={post.media} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p>Invalid media type</p>
                      )}
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          ) : (
            <p>No posts yet.</p>
          )}
        </>
      ) : (
        <div className="login-section">
          <p></p>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
