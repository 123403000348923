// UserChatComponent.js

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { firestore, auth } from "../components/utils/Firebase"; // Adjust the import path
import "./ChatComponent.css"; // Import the CSS file

// ... (previous imports and code)

const UserChatComponent = ({ currentUserUid }) => {
  const { userId } = useParams(); // Extracts the "userId" parameter from the URL
  const supportUserUid = "wx7ZE1RGKHfVr9kDEmESupIGgQx1";
  // Assuming the admin's ID is passed through the route
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    console.log("UserChatComponent - userId:", userId);
    console.log("UserChatComponent - adminId:", supportUserUid);
    console.log("UserChatComponent - user:", auth.currentUser?.uid);
    if (!supportUserUid) {
      console.error("Admin ID is empty or undefined!");
    }
    const chatId = generateChatId(auth.currentUser?.uid, supportUserUid);
    const messagesRef = collection(firestore, "chats", chatId, "messages");
    const messagesQuery = query(messagesRef, orderBy("timestamp"));

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messagesArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesArray);
    });

    return () => unsubscribe();
  }, [supportUserUid, userId]);

  useEffect(() => {
    // Scroll to the bottom when new messages are added
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (newMessage.trim() !== "") {
      const chatId = generateChatId(auth.currentUser?.uid, supportUserUid);
      try {
        const newMessageRef = collection(
          firestore,
          "chats",
          chatId,
          "messages"
        );
        await addDoc(newMessageRef, {
          userId: auth.currentUser?.uid,
          content: newMessage,
          timestamp: new Date(),
        });
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const hasUserMessages = messages.length > 0;

  const handleDefaultMessage = async (defaultMessage) => {
    const chatId = generateChatId(auth.currentUser?.uid, supportUserUid);
    try {
      const newMessageRef = collection(firestore, "chats", chatId, "messages");
      await addDoc(newMessageRef, {
        userId: auth.currentUser?.uid,
        content: defaultMessage,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chat-container">
      {auth.currentUser ? (
        <div className="messages-container">
          {messages.map((message) => (
            <div
              key={message.id}
              className={
                message.userId === auth.currentUser?.uid ? "sent" : "received"
              }
            >
              {message.content}
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>
      ) : (
        <div className="auth-message message-container">
          <div className="auth-message ">
            <h2>
              You need to be signed in to speak to our representative.This is
              necessary for data analysis
            </h2>
            <Link to={"/Log-in"}>
              <button style={{ background: "rgb(61, 2, 2)" }}>Log in</button>
            </Link>
          </div>
          <form className="message-input-container">
            <input type="text" placeholder="Type your message..." />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
      {auth.currentUser && (
        <form onSubmit={handleSendMessage} className="message-input-container">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
          />
          <button type="submit">Send</button>
        </form>
      )}
      {!hasUserMessages && auth.currentUser && (
        <div className="default-messages-container" style={{ display: "flex" }}>
          <button
            style={{ background: "rgb(61, 2, 2)" }}
            onClick={() =>
              handleDefaultMessage(
                "Hello!, I would love to inquire about your services"
              )
            }
          >
            Inquiry
          </button>
          <button
            style={{ background: "rgb(61, 2, 2)" }}
            onClick={() =>
              handleDefaultMessage(
                "I have a complain that requires attention as regards your services"
              )
            }
          >
            Complains
          </button>
        </div>
      )}
    </div>
  );
};

export default UserChatComponent;

// ... (remaining code)

// Helper function to generate a unique chat ID
const generateChatId = (userId, supportUserUid) => {
  const sortedIds = [userId, supportUserUid].sort();
  return `${sortedIds[0]}_${sortedIds[1]}`;
};
