import React, { useEffect, useState } from "react";
import {
  FaHome,
  FaAward,
  FaNewspaper,
  FaBriefcase,
  FaCashRegister,
  FaUser,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce"; // Import debounce from lodash
import "./BottomNavBar.css";
import NotificationIcon from "../notification/NotificationIcons";

const BottomNavBar = ({ isAuthenticated, isAdmin }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(20);
  const navigate = useNavigate();

  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > prevScrollPos) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  }, 200); // Adjust the debounce time as needed

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, handleScroll]);

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className={`bottom-navbar ${isVisible ? "visible" : "hidden"}`}>
      <span onClick={() => handleClick("/home")} className="nav-item">
        <FaHome />
      </span>
      <span onClick={() => handleClick("/achievement")} className="nav-item">
        <FaAward />
      </span>

      <span onClick={() => handleClick("/blog")} className="nav-item">
        <FaNewspaper />
      </span>
      {isAuthenticated && (
        <span onClick={() => handleClick("/create")} className="nav-item">
          <FaBriefcase />
        </span>
      )}

      <span onClick={() => handleClick("/workshop")} className="nav-item">
        <FaCashRegister />
      </span>
      {isAuthenticated && (
        <span onClick={() => handleClick("/profile")} className="nav-item">
          <FaUser />
        </span>
      )}
    </div>
  );
};

export default BottomNavBar;
