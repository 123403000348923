import React from "react";
import { FaBriefcase, FaFeatherAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function BlogButton({ isAuthenticated, isAdmin = false }) {
  const navigate = useNavigate();

  const ButtonOnClicked = () => {
    navigate("/create-blog");
  };

  return (
    <div>
      {isAdmin && (
        <button onClick={ButtonOnClicked} className="arrow">
          <FaFeatherAlt />
        </button>
      )}
    </div>
  );
}

export default BlogButton;
