import React, { useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { collection, addDoc, firestore } from "../components/utils/Firebase";
import { serverTimestamp } from "firebase/firestore";
import PostImage from "../business/PostImage";

const CreateBlog = () => {
  const history = useNavigate();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [blogImage, setBlogImage] = useState("");

  const handleCreateBlog = async () => {
    try {
      const blogData = {
        title,
        image: blogImage,
        content,
        author,
        createdAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(firestore, "blogs"), blogData);

      console.log("Blog post created with ID: ", docRef.id);

      // Redirect to the blog details page after creating the blog
      history(`/blog/${docRef.id}`);
    } catch (error) {
      console.error("Error creating blog post: ", error);
    }
  };

  const handleImageUpload = (imageUrl) => {
    setBlogImage(imageUrl);
  };

  return (
    <div>
      <h2>Create a New Blog</h2>
      <form>
        <PostImage onImageUpload={handleImageUpload} required />
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label htmlFor="content">Content:</label>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={(value) => setContent(value)}
        />

        <label htmlFor="content">Author:</label>
        <input
          type="text"
          id="title"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
        <button type="button" onClick={handleCreateBlog}>
          Create Blog
        </button>
      </form>
    </div>
  );
};

export default CreateBlog;
