// Firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  addDoc,
  updateDoc,
  doc,
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAoYZidjgygVQ_qKVzwiEOUlG1AaH4Cx3g",
  authDomain: "alliance-f33f0.firebaseapp.com",
  databaseURL: "https://alliance-f33f0-default-rtdb.firebaseio.com",
  projectId: "alliance-f33f0",
  storageBucket: "alliance-f33f0.appspot.com",
  messagingSenderId: "839749143274",
  appId: "1:839749143274:web:945824b198b51801fc2593",
  measurementId: "G-R1RPD82PYC",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp); // Update this line

const storage = getStorage(firebaseApp);

const authStateChanged = (callback) => {
  return auth.onAuthStateChanged(callback);
};

const initializeDatabase = () => {
  const postsRef = ref(database, "posts");
  set(postsRef, {
    postId1: {
      title: "Sample Title 1",
      description: "Sample Description 1",
      image: "sample-image-1.jpg",
      link: "https://example.com/1",
    },
    postId2: {
      title: "Sample Title 2",
      description: "Sample Description 2",
      image: "sample-image-2.jpg",
      link: "https://example.com/2",
    },
    // Add more posts as needed
  });
};

export {
  auth,
  database,
  storage,
  firestore,
  authStateChanged,
  firebaseApp as default,
  initializeDatabase,
  orderBy,
  collection,
  getDocs,
  setDoc,
  limit,
  addDoc,
  onSnapshot,
  uploadBytes,
  storageRef,
  ref,
  deleteObject,
  getDownloadURL,
  doc,
  updateDoc,
  getDoc,
  uploadBytesResumable,
  where,
  query,
};
