import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../components/utils/Firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import "./UpdateProfile.css";
import PostImage from "./PostImage";

const UpdateProfile = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [image, setImage] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        const userRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUsername(userData.username || "");
          setBio(userData.bio || "");
          setImage(userData.image || "");
          setSpecialty(userData.specialty || "");
          setTwitter(userData.twitter || "");
          setFacebook(userData.facebook || "");
          setInstagram(userData.instagram || "");
          setLinkedin(userData.linkedin || "");
        } else {
          console.error("User document not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    if (!username || !bio || !specialty) {
      setError("All fields are required");
      return;
    }

    // Update user profile
    try {
      const user = auth.currentUser;
      const userRef = doc(firestore, "users", user.uid);

      await updateDoc(
        userRef,
        {
          username,
          bio,
          specialty,
          ...(image && { image }), // Only include image if it's not empty
          twitter,
          facebook,
          instagram,
          linkedin,
        },
        { merge: true }
      );

      console.log("Profile updated successfully");
      navigate("/user-profile");
    } catch (error) {
      setError("Error updating profile. Please try again.");
      console.error("Error updating profile:", error);
    }
  };

  const handleProfileUpload = (imageUrl) => {
    setImage(imageUrl);
  };

  return (
    <div className="update-profile-container">
      <h2>Update Profile</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Profile Image:
          <PostImage onImageUpload={handleProfileUpload} />
        </label>
        <input
          placeholder="Enter Username"
          type="text"
          required
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <textarea
          placeholder="Enter Bio"
          className="update-textarea"
          id="bio"
          required
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />

        <label style={{ justifyContent: "justify" }} htmlFor="specialty">
          Specialty:
        </label>
        <select
          required
          className="option-select"
          id="specialty"
          value={specialty}
          onChange={(e) => setSpecialty(e.target.value)}
        >
          <option value="software-engineer">Software Engineer</option>
          <option value="graphic-designer">Graphic Designer</option>
          <option value="data-scientist">Data Scientist</option>
          <option value="web-developers">Web Developer</option>
          <option value="logo-designer">Logo Designer</option>
          <option value="ui/ux designer">UI/UX Designer</option>
        </select>

        {/* Social Media Fields */}
        <label>
          <input
            type="text"
            placeholder="Twitter URL e.g https://twitter.com/username"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
            className="update-input"
          />
        </label>

        <label>
          <input
            className="update-input"
            type="text"
            placeholder="Facebook URL e.g https://facebook.com/linked-to-profile"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
        </label>

        <label>
          <input
            type="text"
            className="update-input"
            placeholder="Instagram URL eg https://instagram.com/username"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </label>

        <label>
          <input
            type="text"
            className="update-input"
            placeholder="LinkedIn URL e.g https://linkedin.com/username"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </label>

        <button type="submit">Update Profile</button>
      </form>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UpdateProfile;
