import React, { useState } from "react";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { FiUpload, FiLoader } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import firebaseApp from "../components/utils/Firebase";

// Import the CSS file for styling

const PostImage = ({ onImageUpload }) => {
  const firebase = firebaseApp;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setLoading(true);

      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);

        const storage = getStorage(firebase);
        const storageReference = storageRef(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(
          storageReference,
          compressedFile
        );

        await uploadTask;

        const imageUrl = await getDownloadURL(storageReference);
        console.log("Image URL", imageUrl);

        onImageUpload(imageUrl);
        setImage(imageUrl);
      } catch (error) {
        console.error("Error compressing or uploading image: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="post-image-container">
      <label htmlFor="imageUpload" className="image-upload-label">
        <input
          type="file"
          accept="image/*"
          id="imageUpload"
          onChange={handleImageChange}
        />
        <FiUpload className="upload-icon" />
        {loading && <FiLoader className="loader" />}
        {image && <img src={image} alt="Post" className="uploaded-image" />}
      </label>
    </div>
  );
};

export default PostImage;
