import React, { useState } from "react";
import { doc, getDoc, firestore } from "../components/utils/Firebase";
import {
  getDownloadURL,
  ref as storageRef,
  getStorage,
} from "firebase/storage";

import "./receive.css";

const ReceiveJob = () => {
  const [fileIdInput, setFileIdInput] = useState("");
  const [jobDetails, setJobDetails] = useState(null);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const handleFileIdChange = (e) => {
    setFileIdInput(e.target.value);
  };

  const fetchJobDetails = async () => {
    const jobDocRef = doc(firestore, "jobs", fileIdInput);

    try {
      const jobSnapshot = await getDoc(jobDocRef);

      if (jobSnapshot.exists()) {
        setJobDetails(jobSnapshot.data());
        setShowPaymentDetails(false); // Reset to hide payment details when fetching new job
      } else {
        console.log("Job not found");
      }
    } catch (error) {
      console.error("Error fetching job details:", error.message);
    }
  };

  const handleTogglePaymentDetails = () => {
    setShowPaymentDetails(!showPaymentDetails);
  };

  const handlePreview = async () => {
    if (jobDetails) {
      // Use the previewImage link for download
      const link = document.createElement("a");
      link.href = jobDetails.previewImage;
      link.download = `Job_${jobDetails.fileId}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownload = async () => {
    if (jobDetails) {
      if (jobDetails.paymentReceived) {
        try {
          const response = await fetch(jobDetails.previewImage);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);

          // Create a temporary link
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Job_${jobDetails.fileId}`);
          link.textContent = "Download File";

          // Append the link to the document or any desired container
          document.body.appendChild(link);

          // Simulate a click to trigger the download
          link.click();

          // Remove the temporary link from the document
          document.body.removeChild(link);

          // Clean up the temporary URL
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error during download:", error.message);
        }
      } else {
        // Alert if payment has not been received
        alert("Payment has not been received. Cannot download the file.");
      }
    }
  };

  return (
    <div className="my-work">
      <h2>Receive Job Details</h2>

      <div style={{ borderTopRightRadius: "30px" }}>
        <label htmlFor="fileId">Enter File ID:</label>
        <input
          type="text"
          id="fileId"
          value={fileIdInput}
          onChange={handleFileIdChange}
        />
        <button style={{ background: "#460101" }} onClick={fetchJobDetails}>
          Fetch Job
        </button>
      </div>

      {jobDetails ? (
        <div className="job-details">
          <p>Note: {jobDetails.description}</p>
          <p>Price: ${jobDetails.price}</p>
          <p>Category: {jobDetails.category}</p>
          <p>Payment Received: {jobDetails.paymentReceived ? "Yes" : "No"}</p>

          {/* Toggle button for payment details */}
          <div
            onClick={handleTogglePaymentDetails}
            style={{ background: "#460101" }}
          >
            {showPaymentDetails
              ? "Hide Payment Details"
              : "Show Payment Details"}
          </div>

          {showPaymentDetails && jobDetails.paymentReceived === false && (
            <>
              <p>Bank Name: {jobDetails.paymentDetails.bankName}</p>
              <p>Account Number: {jobDetails.paymentDetails.accountNumber}</p>
              {/* Add other payment details here */}
            </>
          )}

          {/* Download button */}
          <button
            onClick={handlePreview}
            disabled={!jobDetails.paymentReceived}
            style={{
              cursor: !jobDetails.paymentReceived ? "not-allowed" : "pointer",
              background: "#460101",
            }}
          >
            Preview
          </button>
          {/*  <button
            onClick={handleDownload}
            disabled={!jobDetails.paymentReceived}
            style={{
              cursor: !jobDetails.paymentReceived ? "not-allowed" : "pointer",
              background: "#460101",
            }}
          >
            Download
          </button>
          */}

          {jobDetails.paymentReceived !== true && (
            <p style={{ color: "red" }}>Payment has not been confirmed.</p>
          )}
        </div>
      ) : (
        <p>No job details available. Please enter a valid File ID and fetch.</p>
      )}
    </div>
  );
};

export default ReceiveJob;
