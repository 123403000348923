import React, { useEffect, useState } from "react";
import {
  firestore,
  collection,
  getDocs,
  addDoc,
  doc,
} from "../components/utils/Firebase";
import { deleteDoc } from "firebase/firestore"; // Make sure to import the necessary Firebase modules
import PostImage from "./PostImage";
import "./Business.css";
import { FiLoader, FiTrash } from "react-icons/fi";

const BusinessCard = ({ userId, isAdmin }) => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddBusinessForm, setShowAddBusinessForm] = useState(false);
  const [newBusiness, setNewBusiness] = useState({
    logo: "",
    coverImage: "",
    description: "",
    title: "",
    websiteLinks: [],
  });

  useEffect(() => {
    // Fetch all businesses from Firebase Firestore
    const fetchBusinesses = async () => {
      try {
        const businessesRef = collection(firestore, "businesses");
        const snapshot = await getDocs(businessesRef);

        const businessesData = [];
        snapshot.forEach((doc) => {
          businessesData.push({ id: doc.id, ...doc.data() });
        });

        setBusinesses(businessesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching businesses:", error);
      }
    };

    fetchBusinesses();
  }, []);

  const handleLogoUpload = (imageUrl) => {
    setNewBusiness({ ...newBusiness, logo: imageUrl });
  };

  const handleCoverImageUpload = (imageUrl) => {
    setNewBusiness({ ...newBusiness, coverImage: imageUrl });
  };

  const handleAddBusinessClick = () => {
    setShowAddBusinessForm(true);
  };

  const handleSaveBusiness = async () => {
    try {
      // Save the new business data to Firestore with the user ID
      const newBusinessRef = await addDoc(collection(firestore, "businesses"), {
        ...newBusiness,
      });

      // Reset the form and hide it
      setNewBusiness({
        logo: "",
        coverImage: "",
        description: "",
        title: "",
        services: "",
        websiteLinks: [],
      });
      setShowAddBusinessForm(false);
    } catch (error) {
      console.error("Error saving business data:", error);
    }
  };

  const handleCancelAddBusiness = () => {
    // Reset the form and hide it
    setNewBusiness({
      logo: "",
      coverImage: "",
      description: "",
      title: "",
      websiteLinks: [],
    });
    setShowAddBusinessForm(false);
  };

  if (loading) {
    return <FiLoader className="loader" />;
  }

  const handleDeleteBusiness = async (businessId) => {
    try {
      // Delete the business with the specified ID from Firestore
      await deleteDoc(doc(firestore, "businesses", businessId));

      // Update the businesses state to reflect the deletion
      setBusinesses((prevBusinesses) =>
        prevBusinesses.filter((business) => business.id !== businessId)
      );
    } catch (error) {
      console.error("Error deleting business:", error);
    }
  };

  if (businesses.length === 0 && isAdmin) {
    return (
      <div>
        <button onClick={handleAddBusinessClick}>Add New Job</button>
        {showAddBusinessForm && (
          <div className="business-card">
            {/* Input fields for new business */}
            <label>
              Logo:
              <PostImage onUpload={handleLogoUpload} />
            </label>

            <label>
              Cover Image:
              <PostImage onUpload={handleCoverImageUpload} />
            </label>
            <label>
              Title:
              <textarea
                value={newBusiness.title}
                onChange={(e) => {
                  // Enforce maximum length of 1000 characters
                  if (e.target.value.length <= 100) {
                    setNewBusiness({
                      ...newBusiness,
                      title: e.target.value,
                    });
                  }
                }}
                maxLength={100}
              />
            </label>
            <label>
              Description:
              <textarea
                value={newBusiness.description}
                onChange={(e) => {
                  // Enforce maximum length of 1000 characters
                  if (e.target.value.length <= 1000) {
                    setNewBusiness({
                      ...newBusiness,
                      description: e.target.value,
                    });
                  }
                }}
                maxLength={1000}
              />
            </label>

            <label>
              Website Links:
              <input
                type="text"
                value={newBusiness.websiteLinks.join(",")}
                onChange={(e) =>
                  setNewBusiness({
                    ...newBusiness,
                    websiteLinks: e.target.value.split(","),
                  })
                }
              />
            </label>

            {/* No input field for followers and followings */}

            {/* Add input fields for other properties */}

            <button onClick={handleSaveBusiness}>Save</button>
            <button onClick={handleCancelAddBusiness}>Cancel</button>
          </div>
        )}
        <div>No businesses found.</div>
      </div>
    );
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Completed Job</h2>{" "}
      {isAdmin && <button onClick={handleAddBusinessClick}>Add Job</button>}
      {showAddBusinessForm && (
        <div className="business-card">
          {/* Input fields for new business */}
          <label>
            Logo:
            <PostImage onImageUpload={handleLogoUpload} />
          </label>

          <label>
            Cover Image:
            <PostImage onImageUpload={handleCoverImageUpload} />
          </label>
          <label>
            Title:
            <textarea
              value={newBusiness.title}
              onChange={(e) => {
                // Enforce maximum length of 1000 characters
                if (e.target.value.length <= 100) {
                  setNewBusiness({
                    ...newBusiness,
                    title: e.target.value,
                  });
                }
              }}
              maxLength={100}
            />
          </label>
          <label>
            Description:
            <textarea
              value={newBusiness.description}
              onChange={(e) => {
                // Enforce maximum length of 1000 characters
                if (e.target.value.length <= 1000) {
                  setNewBusiness({
                    ...newBusiness,
                    description: e.target.value,
                  });
                }
              }}
              maxLength={1000}
            />
          </label>

          <label>
            Website Links:
            <input
              type="text"
              value={newBusiness.websiteLinks.join(",")}
              onChange={(e) =>
                setNewBusiness({
                  ...newBusiness,
                  websiteLinks: e.target.value.split(","),
                })
              }
            />
          </label>

          {/* No input field for followers and followings */}

          {/* Add input fields for other properties */}

          <button onClick={handleSaveBusiness}>Save</button>
          <button onClick={handleCancelAddBusiness}>Cancel</button>
        </div>
      )}
      <br />
      {businesses.map((business) => (
        <div key={business.id} className="business-card">
          <img
            src={business.logo}
            alt="Business Logo"
            className="business-logo"
          />
          <img
            src={business.coverImage}
            alt="Business Cover Image"
            className="business-cover"
          />
          <h3>{business.title}</h3>
          <p>{business.description}</p>

          <a
            style={{ textDecoration: "none" }}
            href={business.websiteLinks.join(", ")}
            target="_blank"
          >
            <p> {business.websiteLinks.join(", ")}</p>
          </a>
          {isAdmin && (
            <button onClick={() => handleDeleteBusiness(business.id)}>
              <FiTrash />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default BusinessCard;
