import React from "react";

import figma from "../img/figma.png";
import illustrator from "../img/Adobe_Illustrator_CC_icon.svg.png";
import js from "../img/js.png";
import python from "../img/phython.png";
import vite from "../img/vite.png";
import vue from "../img/vue.png";
import node from "../img/node.png";
import wordpress from "../img/WordPress_blue_logo.svg.png";
import woo from "../img/woo.png";
import elementor from "../img/elementor.png";
import html from "../img/html.jpg";
import ux from "../img/uiux.jpg";
import shopify from "../img/shopify.jpeg";
import reacty from "../img/react.png";
import flutterwave from "../img/flut.png";
import dropship from "../img/dropship.jpg";

const ToolsUsed = () => {
  const toolImages = {
    figma: figma,
    uiux: ux,
    illustrator: illustrator,
    js: js,
    html: html,
    wordpress: wordpress,
    vite: vite,
    react: reacty,
    nodejs: node,
    python: python,
    elementor: elementor,
    shopify: shopify,
    woocommerce: woo,
    dropshipping: dropship,
    flutterwave: flutterwave,
  };

  return (
    <div className="tools">
      <h2>Tools We Use</h2>
      <ul className="service-grid">
        <li className="service-card">
          <img src={toolImages.figma} alt="Figma" /> Figma - for collaborative
          design
        </li>
        <li className="service-card">
          <img src={toolImages.uiux} alt="UI/UX Design" /> UI/UX Design -
          ensuring a seamless user experience
        </li>
        <li className="service-card">
          <img src={toolImages.illustrator} alt="Illustrator" /> Illustrator -
          crafting stunning graphic designs
        </li>
        <li className="service-card">
          <img src={toolImages.js} alt="JavaScript" /> JavaScript (JS) -
          powering interactive web elements
        </li>
        <li className="service-card">
          <img src={toolImages.html} alt="HTML" /> HTML - the backbone of web
          development
        </li>
        <li className="service-card">
          <img src={toolImages.wordpress} alt="WordPress" /> WordPress - for
          versatile and scalable websites
        </li>
        <li className="service-card">
          <img src={toolImages.vite} alt="Vite" /> Vite - a fast, modern
          frontend build tool
        </li>
        <li className="service-card">
          <img src={toolImages.react} alt="Vue.js" /> React.js - for building
          dynamic and responsive user interfaces
        </li>
        <li className="service-card">
          <img src={toolImages.nodejs} alt="Node.js" /> Node.js - enabling
          server-side JavaScript
        </li>
        <li className="service-card">
          <img src={toolImages.python} alt="Python" /> Python - versatile
          programming language for diverse solutions
        </li>
        <li className="service-card">
          <img src={toolImages.elementor} alt="Elementor" /> Elementor -
          enhancing WordPress design capabilities
        </li>
        <li className="service-card">
          <img src={toolImages.shopify} alt="Shopify" /> Shopify - for robust
          e-commerce solutions
        </li>
        <li className="service-card">
          <img src={toolImages.woocommerce} alt="WooCommerce" /> WooCommerce -
          powering customizable online stores
        </li>
        <li className="service-card">
          <img src={toolImages.dropshipping} alt="Dropshipping" /> Dropshipping
          - streamlining product fulfillment
        </li>
        <li className="service-card">
          <img src={toolImages.flutterwave} alt="Flutterwave" /> Flutterwave -
          facilitating secure online payments
        </li>
      </ul>
    </div>
  );
};

export default ToolsUsed;
