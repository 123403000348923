import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firestore } from "../components/utils/Firebase";

const SentJobsList = () => {
  const [sentJobs, setSentJobs] = useState([]);

  const fetchSentJobs = async () => {
    const user = getAuth().currentUser;

    if (user) {
      const userId = user.uid;

      const jobsCollection = collection(firestore, "jobs");

      // Query jobs where the user is the sender (assuming you have a field named 'senderId' in your documents)
      const q = query(jobsCollection, where("senderId", "==", userId));

      try {
        const querySnapshot = await getDocs(q);

        const jobsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSentJobs(jobsData);

        // Save job list to localStorage
        localStorage.setItem("sentJobs", JSON.stringify(jobsData));
      } catch (error) {
        console.error("Error fetching sent jobs:", error.message);
      }
    }
  };

  useEffect(() => {
    // Try to load job list from localStorage on component mount
    const storedJobs = localStorage.getItem("sentJobs");

    if (storedJobs) {
      setSentJobs(JSON.parse(storedJobs));
    }

    // Fetch initially
    fetchSentJobs();

    // Set up an interval to fetch data every 60 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchSentJobs();
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handlePaymentReceived = async (jobId) => {
    try {
      // Update the paymentReceived field for the specific job in Firestore
      const jobRef = doc(firestore, "jobs", jobId);
      await updateDoc(jobRef, {
        paymentReceived: true,
      });

      // Update the local state to reflect the change
      setSentJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.id === jobId ? { ...job, paymentReceived: true } : job
        )
      );

      // Save updated job list to localStorage
      localStorage.setItem("sentJobs", JSON.stringify(sentJobs));

      console.log("Payment Received updated for job", jobId);
    } catch (error) {
      console.error("Error updating Payment Received status:", error.message);
    }
  };

  return (
    <div>
      <h2>Sent Jobs List</h2>
      <div>
        {sentJobs.map((job) => (
          <div className="job-card" key={job.id}>
            <p>Job ID: {job.id}</p>
            <p>Price: ${job.price}</p>
            <p>Category: {job.category}</p>

            {!job.paymentReceived ? (
              <button onClick={() => handlePaymentReceived(job.id)}>
                Confirm Payment Received
              </button>
            ) : (
              <p>Payment Received!</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SentJobsList;
