// SendWork.js
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import FileUpload from "./FileUpload";
import SentJobsList from "./SentJobList";
import "./Sendjob.css";

import {
  firestore,
  collection,
  setDoc,
  doc,
  updateDoc,
  auth,
} from "../components/utils/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import { FiArrowLeftCircle } from "react-icons/fi";
import BackButton from "../components/utils/BackButton";
import PaymentDetails from "./PaymentDetails"; // New component

const SendWork = () => {
  const [fileUrl, setFileUrl] = useState("");
  const [price, setPrice] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [downloadURL, setDownloadURL] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState(""); // New state for description
  const [jobId, setJobId] = useState("");
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [userId, setUserId] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");

  const categories = [
    "Website",
    "Web Apps",
    "Logos",
    "Flyers",
    "Posters",
    "ID",
    "Business Card",
    "Social Ads",
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleFileUpload = (url, downloadURL) => {
    setFileUrl(url);
    setPreviewImage(url);
    setDownloadURL(downloadURL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newJobId = uuidv4();
    setJobId(newJobId);

    const jobDetails = {
      senderId: userId,
      fileId: newJobId,
      price,
      previewImage,
      downloadURL,
      category,
      description, // Include the description in the Firestore document
      paymentReceived,
      paymentDetails: {
        bankName,
        accountNumber,
        accountType,
      },
    };

    const JobDocRef = doc(firestore, "jobs", jobId);

    try {
      await setDoc(JobDocRef, jobDetails);

      setPrice("");
      setPreviewImage("");
      setCategory("");
      setDescription("");
      setPaymentReceived(false);
      setDownloadURL("");
      setBankName("");
      setAccountNumber("");
      setAccountType("");
    } catch (error) {
      console.error("Error adding job details to Firestore:", error);
    }
  };

  return (
    <div>
      <Link to="/workshop">
        <BackButton />
      </Link>
      <div className="send-work-container">
        <div className="joblist">
          <SentJobsList />
        </div>
        <div className="form-container">
          <h2>Create a New Job</h2>
          <div>
            <form onSubmit={handleSubmit} className="send-work-form">
              <FileUpload
                onUpload={(url) => handleFileUpload(url, downloadURL)}
                jobId={jobId}
              />

              <div className="form-group">
                <label htmlFor="price">Price:</label>
                <input
                  type="number"
                  id="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>Category:</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                  className="input-field"
                >
                  <option value="">Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group" style={{ display: "block" }}>
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="bankName">Bank Name:</label>
                <input
                  type="text"
                  id="bankName"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="accountNumber">Account Number:</label>
                <input
                  type="text"
                  id="accountNumber"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="accountType">Account Type:</label>
                <input
                  type="text"
                  id="accountType"
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                  required
                />
              </div>

              <button type="submit">Create Job</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendWork;
