import React, { useState, useEffect } from "react";
import {
  firestore,
  collection,
  getDocs,
  authStateChanged,
  query,
  orderBy,
  limit,
  getDoc,
  doc,
} from "../components/utils/Firebase";
import LikeButton from "../components/utils/LikeButton";
import { Link } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import Chat from "../components/Chat";

import ShareButton from "../components/utils/ShareButton";
import PostButton from "../components/utils/PostButton";
import BannerSlider from "../components/utils/BannerSlider";
import CommentButton from "../components/utils/CommentButton";

import ChatButton from "../messages/ChatButton";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const Feeds = () => {
  const [posts, setPosts] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showAllPosts, setShowAllPosts] = useState(true); // New state variable
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null); // New state for admin checks

  const [categories, setCategories] = useState([
    "Website",
    "Web Apps",
    "Logos",
    "Flyers",
    "Posters",
    "Graphic Editing",
    "Stickers",
    "ID",
    "Business Card",
    "Social Ads",
  ]);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "blogs"),
            orderBy("createdAt", "desc"),
            limit(3)
          )
        );

        const latestBlogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestBlogs(latestBlogsData);
      } catch (error) {
        console.error("Error fetching latest blog posts: ", error);
      }
    };

    fetchLatestBlogs();
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "harryjeffersonswagking@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async (userId) => {
      try {
        const userDocRef = doc(firestore, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          return userDocSnapshot.data(); // Return user details if the document exists
        } else {
          console.error("User document does not exist for userId:", userId);
          return null;
        }
      } catch (error) {
        console.error("Error fetching user details:", error.message);
        return null;
      }
    };

    const fetchPosts = async () => {
      try {
        const postsCollection = collection(firestore, "posts");
        const snapshot = await getDocs(postsCollection);

        const postsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const postData = {
              id: doc.id,
              ...doc.data(),
            };

            // Fetch user details for each post
            const userDetails = await fetchUserDetails(postData.userId);

            if (userDetails) {
              postData.username = userDetails.username;
              postData.userImage = userDetails.Image; // Assuming there's a field named 'userImage'
            }

            return postData;
          })
        );

        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching posts:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // Trigger the filter function when the page mounts
    filterPostsByCategory(""); // Call with an empty string to show all posts
    setShowAllPosts(true);
  }, [posts]); // Empty dependency array means this effect runs once after the initial render
  useEffect(() => {
    console.log("Updated Posts:", posts);
  }, [posts]);

  const filterPostsByCategory = (category) => {
    console.log("Selected Category:", category);
    setSelectedCategory(category);

    if (category === "") {
      // Display all posts when no category is selected
      setFilteredPosts(posts);
      console.log(posts);
      setShowAllPosts(true);
    } else {
      // Filter posts by the selected category
      const filtered = posts.filter((post) => post.category === category);
      console.log("Filtered Posts:", filtered);
      setFilteredPosts(filtered);
      setShowAllPosts(false);
    }
  };

  const resetFilter = () => {
    setSelectedCategory("");
    setFilteredPosts(posts);
  };
  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div>
      <BannerSlider />
      <div className="main-container">
        <br />
        <Link className="linkStyle" to={"/about"}>
          <button className="view">Learn About Us</button>
        </Link>
        <br />
        <div className="filter">
          <label>Filter by Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => filterPostsByCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <button className="filter-button" onClick={resetFilter}>
            default
          </button>
        </div>
        <h2>
          {showAllPosts ? "All Templates" : `Templates - ${selectedCategory}`}
        </h2>
        <div id="projects-grid">
          {filteredPosts.map((post) => (
            <div className="project-div comment" key={post.id}>
              <Link to={`/post/${post.id}`} className="linkStyle">
                <h3 className="title">{post.title}</h3>
                <p>
                  Posted by:{" "}
                  <Link className="linkStyle" to={`/user/${post.userId}`}>
                    {post.userImage}
                    {post.username}
                  </Link>
                </p>
                {post.mediaType === "image" ? (
                  <img className="media" src={post.media} alt="Post" />
                ) : post.mediaType === "video" ? (
                  <video className="media" controls>
                    <source src={post.media} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <p>Invalid media type</p>
                )}
              </Link>
              <div className="engagement">
                <div className="engagement-button">
                  <LikeButton postId={post.id} />
                </div>

                <div className="engagement-button">
                  <ShareButton
                    postTitle={post.title}
                    postCategory={post.category}
                    postImageURL={post.postImage}
                    postPath={`/#/post/${post.id}`}
                  />
                </div>
                <div className="engagement-button">
                  <CommentButton postId={post.id} />
                </div>
              </div>
              <br />

              <div className="project-body">
                <p>Price: N{post.price}</p>
                <p>Time Interval: {post.duration} Days</p>
                <a href={post.link}></a>
                <Chat />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feeds;
