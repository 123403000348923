// Welcome.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";

const Welcome = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    // Navigate to the SignUp component
    navigate("/Sign-up");
  };

  const handleSkip = () => {
    // Navigate to the home component
    navigate("/home");
  };

  const handleLogIn = () => {
    // Navigate to the LogIn component
    navigate("/sign-in");
  };

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h2 className="welcome-title">Welcome to Alliance Explorer</h2>
        <p className="welcome-message">All things Digital</p>
        <div className="welcome-buttons">
          <div>
            <button className="button" onClick={handleLogIn}>
              Log in
            </button>
          </div>
          <p> or</p>
        </div>
        <div>
          <p className="signup-link">
            Don't have an account? <span onClick={handleSignUp}>Sign up</span> .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
