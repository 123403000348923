import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  firestore,
  auth,
  storage,
  storageRef,
  deleteObject,
} from "../components/utils/Firebase";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import Chat from "../components/Chat";
import "./Description.css";
import ConfirmationModal from "../components/utils/ConfirmModal";

import ShareButton from "../components/utils/ShareButton";
import { FiHeart, FiLoader, FiTrash } from "react-icons/fi";
import tag from "../img/price.png";
import time from "../img/duration.png";
import Comment from "../components/utils/Comment";

const PostDetails = ({ isAdmin }) => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userIsAuthorizedToDeletePost, setUserIsAuthorizedToDeletePost] =
    useState(false); // New state variable

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        //console.log("Before fetching");
        const postDocRef = doc(firestore, "posts", postId);
        // console.log("After doc");
        const snapshot = await getDoc(postDocRef);
        // console.log("After getDoc");

        if (snapshot.exists()) {
          //  console.log("Post exists");
          setPost(snapshot.data());
          const currentUser = auth.currentUser;
          if (
            currentUser &&
            (currentUser.uid === snapshot.data().userId || isAdmin)
          ) {
            setUserIsAuthorizedToDeletePost(true);
          }
        } else {
          // console.log("Post not found");
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    fetchPostDetails();
  }, [postId]);

  const handleDeletePost = async () => {
    // Open the confirmation modal
    setShowConfirmationModal(true);
  };

  const confirmDeletePost = async () => {
    try {
      // Get the post document
      const postDocRef = doc(firestore, "posts", postId);
      const postDoc = await getDoc(postDocRef);

      if (!postDoc.exists()) {
        console.error("Post not found.");
        return;
      }

      // Delete the image from storage
      const imageUrl = postDoc.data().media;
      if (imageUrl) {
        const storageReference = storageRef(storage, imageUrl);
        await deleteObject(storageReference);
        console.log("Image deleted from storage successfully!");
      }

      // Delete the post document
      await deleteDoc(postDocRef);
      console.log("Post deleted successfully!");

      // Optionally, you can show an alert message after deletion
      alert("Post deleted successfully!");

      // Optionally, redirect the user to a different page or perform other actions after deletion

      // Close the confirmation modal after successful deletion
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const closeConfirmationModal = () => {
    // Close the confirmation modal if the user cancels
    setShowConfirmationModal(false);
  };

  return (
    <div className="description">
      {post ? (
        <>
          <h3 className="description-head">{post.title}</h3>
          <div className="description-div">
            <div className="description-img">
              {post.mediaType === "image" ? (
                <img src={post.media} alt="Post" />
              ) : post.mediaType === "video" ? (
                <video controls>
                  <source src={post.media} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p>Invalid media type</p>
              )}
            </div>
            <div className="description-body">
              <p>
                <strong>Description: </strong>
                {post.description}
              </p>
              <p>
                <strong>Category</strong>: {post.category}
              </p>
              <p>
                {" "}
                <span>
                  <img className="price-img" src={tag} />
                </span>
                <span>
                  <strong>Price:</strong> N{post.price}
                </span>
              </p>

              <p>
                <span>
                  <img className="time-img" src={time} />
                </span>
                <span>
                  {" "}
                  <strong>Delivery Duration </strong>
                  {post.duration} Days
                </span>
              </p>

              <br />
              <br />
              <Chat />
              <div className="engagement">
                <div className="engagement-button">
                  <FiHeart />
                  {post.likeCount}
                </div>
                <div className="engagement-button">
                  <ShareButton />
                </div>
                <div className="engagement-button">
                  {userIsAuthorizedToDeletePost && (
                    <span onClick={handleDeletePost} className="delete-button">
                      <FiTrash />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <br />
          </div>
          <div>
            <Comment postId={postId} />
          </div>

          <ConfirmationModal
            isOpen={showConfirmationModal}
            onClose={closeConfirmationModal}
            onConfirm={confirmDeletePost}
          />
        </>
      ) : (
        <p>
          <FiLoader className="loader" />
        </p>
      )}
    </div>
  );
};

export default PostDetails;
