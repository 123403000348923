import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  firestore,
  collection,
  getDocs,
  authStateChanged,
  query,
  orderBy,
  limit,
} from "../components/utils/Firebase";

import aboutmages from "../img/home.png";
import { FaClock, FaGem, FaPaintBrush, FaSafari } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faAd,
  faBullhorn,
  faPalette,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import MeetOurTeam from "./Team";
import Footer from "../components/Footer";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const About = () => {
  const [latestBlogs, setLatestBlogs] = useState([]);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "blogs"),
            orderBy("createdAt", "desc"),
            limit(2)
          )
        );

        const latestBlogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestBlogs(latestBlogsData);
      } catch (error) {
        console.error("Error fetching latest blog posts: ", error);
      }
    };

    fetchLatestBlogs();
  }, []);
  return (
    <div>
      {/* Introductory Section */}
      <section className="about-header">
        <div className="about-image">
          <img src={aboutmages} />
        </div>
        <div className="about-bodi">
          <h2>Welcome to Alliance Explorer</h2>
          <p>
            We form an alliance with your business then we explore unlimited
            possibilities to make your brand stand out. We are Your gateway to
            cutting-edge solutions in the digital realm. We bring creativity and
            functionality together to elevate your brand in the digital space.
          </p>
        </div>
      </section>
      <section>
        <h2>Our Services</h2>
        <p className="about-p">
          Alliance Explorer specializes in providing a range of digital
          services, including stunning graphic designs for logos, flyers,
          stickers, posters, and more. Our expertise extends to UI/UX designs,
          web services, web applications, and comprehensive ad and marketing
          campaign programs.
        </p>
        <div className="service-grid">
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faPalette} className="service-icon" />
            </div>
            Graphic Designs
          </div>
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faDesktop} className="service-icon" />
            </div>
            Website/Web app development
          </div>
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
            </div>
            Mobile app development
          </div>
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faAd} className="service-icon" />
            </div>
            Ads services
          </div>
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faBullhorn} className="service-icon" />
            </div>
            Marketing campaign
          </div>
          <div className="service-card">
            <div className="gradient-border">
              <FontAwesomeIcon icon={faCog} className="service-icon" />
            </div>
            Social media management
          </div>
        </div>
      </section>

      <MeetOurTeam />

      {/* Blog Section */}
      <section>
        <h2>Explore Our Insights</h2>
        <p className="about-p">
          Stay informed and inspired through our blog, where we share industry
          trends, design philosophies, and success stories. Join us on the
          journey of continuous learning and discovery.
        </p>
        <div id="projects-grid">
          {latestBlogs.map((blog, index) => (
            <div className="project-div comment" key={index}>
              <Link className="linkStyle" to={`/blog/${blog.id}`}>
                <h2>{blog.title}</h2>
                <img className="project-div-img" src={blog.image} alt="" />
                <div className="content">
                  <p>
                    Author: {blog.author || "Unknown Author"}{" "}
                    <span>
                      at{" "}
                      {blog.createdAt &&
                        new Date(blog.createdAt.toDate()).toLocaleString()}
                    </span>
                  </p>
                </div>
              </Link>
              <Link className="linkStyle" to={`/blog/${blog.id}`}>
                <button className="view">Read more</button>
              </Link>
            </div>
          ))}
        </div>

        <Link className="linkStyle" to={`/blog`}>
          <button className="view">All blog post</button>
        </Link>
      </section>

      {/* Service Section */}

      <section class="why-choose-us">
        <div class="why-choose-us-container">
          <h2 class="section-title">Why Choose Us</h2>
          <ul class="features-list">
            <li class="feature-item">
              <span className="fa">
                <FaClock />
              </span>
              <strong>Fast Delivery:</strong> We prioritize efficiency to ensure
              your projects are delivered promptly without compromising quality.
            </li>
            <li class="feature-item">
              <span className="fa">
                <FaGem />
              </span>
              <strong>Premium Features:</strong> Experience excellence with our
              premium design and development features, tailored to elevate your
              digital presence.
            </li>
            <li class="feature-item">
              <span className="fa">
                <FaPaintBrush />
              </span>
              <strong>A Touch of Uniqueness:</strong> Stand out from the crowd
              with our creative solutions, adding a unique and captivating touch
              to your brand.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default About;
