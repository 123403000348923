import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { auth, firestore } from "../components/utils/Firebase";
import "./ChatComponent.css";

const AdminChatComponent = ({ isAdmin, chatId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!chatId) {
      console.error("Chat ID is empty or undefined!");
      return;
    }

    const messagesRef = collection(firestore, "chats", chatId, "messages");
    const messagesQuery = query(messagesRef, orderBy("timestamp"));

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messagesArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesArray);
    });

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (newMessage.trim() !== "" && chatId) {
      try {
        const newMessageRef = collection(
          firestore,
          "chats",
          chatId,
          "messages"
        );
        await addDoc(newMessageRef, {
          userId: auth.currentUser.uid,
          content: newMessage,
          timestamp: new Date(),
        });
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleClearMessages = async () => {
    if (chatId) {
      try {
        const messagesRef = collection(firestore, "chats", chatId, "messages");
        const querySnapshot = await getDocs(messagesRef);

        // Delete all messages in the chat
        const deletePromises = querySnapshot.docs.map((messageDoc) =>
          deleteDoc(doc(firestore, "chats", chatId, "messages", messageDoc.id))
        );

        await Promise.all(deletePromises);
      } catch (error) {
        console.error("Error clearing messages:", error);
      }
    }
  };

  return (
    <div className="chat-container">
      <div className="messages-container">
        {messages.map((message) => (
          <div
            key={message.id}
            className={
              message.userId === auth.currentUser.uid ? "sent" : "received"
            }
          >
            {message.content}
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <button onClick={handleClearMessages}>Clear Messages</button>
      <form onSubmit={handleSendMessage} className="message-input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default AdminChatComponent;
