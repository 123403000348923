// Navbarmenu.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { auth } from "../components/utils/Firebase";
import "./Navbarmenu.css"; // Import your custom styles
import Menu from "./Menu";
import logo from "../img/logo2.png";
import support from "../img/support.jpg";
import { FaPowerOff } from "react-icons/fa";
import BottomNavBar from "./Navbar";

const Navbarmenu = () => {
  const navigate = useNavigate();

  const [showOptions, setShowOptions] = useState(false);

  const user = auth.currentUser;

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div>
      <div className="navbar-container">
        <Menu />

        <Link to={`/`} className="logo-link">
          <img
            src={logo} // Replace with the actual path to your logo
            alt="Logo"
            className="logo"
          />
        </Link>

        <Link to={`/chat`} className="support">
          <img
            src={support} // Replace with the actual path to your logo
            alt="Logo"
            className="logo"
          />
        </Link>

        <div className="user-actions">
          {user ? (
            <>
              {user.photoURL ? (
                <img
                  src={user.photoURL}
                  alt={`${user.displayName}'s profile`}
                  className="profile-icon"
                />
              ) : (
                <div className="default-profile-icon">
                  {user.displayName?.charAt(0).toUpperCase()}
                </div>
              )}
              <Link to={`/profile`} className="profile-link">
                {user.displayName}
              </Link>
              {showOptions && (
                <div className="options-menu">
                  <ul>
                    <li>
                      <button onClick={handleLogout}>Logout</button>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <Link to="/Log-in">
              <button className="logout-button">
                <FaPowerOff />{" "}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbarmenu;
