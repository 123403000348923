import React, { useState, useEffect } from "react";
import {
  firestore,
  collection,
  getDocs,
  authStateChanged,
  query,
  orderBy,
  limit,
} from "../components/utils/Firebase";
import LikeButton from "../components/utils/LikeButton";
import { Link, useParams } from "react-router-dom";
import { FiBookmark, FiLoader, FiMessageCircle } from "react-icons/fi";
import Chat from "../components/Chat";

import ShareButton from "../components/utils/ShareButton";
import PostButton from "../components/utils/PostButton";
import BannerSlider from "../components/utils/BannerSlider";
import CommentButton from "../components/utils/CommentButton";
import { FaExchangeAlt } from "react-icons/fa";
import RetweetButton from "../components/utils/RetweetButton";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const VideoFeeds = () => {
  const [posts, setPosts] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showAllPosts, setShowAllPosts] = useState(true); // New state variable
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null); // New state for admin checks

  const [categories, setCategories] = useState([
    "Website",
    "Web Apps",
    "Logos",
    "Flyers",
    "Posters",
  ]);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "blogs"),
            orderBy("createdAt", "desc"),
            limit(3)
          )
        );

        const latestBlogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestBlogs(latestBlogsData);
      } catch (error) {
        console.error("Error fetching latest blog posts: ", error);
      }
    };

    fetchLatestBlogs();
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "harryjeffersonswagking@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(firestore, "posts"); // Use collection method
        const snapshot = await getDocs(postsCollection);

        const postsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching posts:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // Trigger the filter function when the page mounts
    filterPostsByCategory(""); // Call with an empty string to show all posts
    setShowAllPosts(true);
  }, [posts]); // Empty dependency array means this effect runs once after the initial render
  useEffect(() => {
    console.log("Updated Posts:", posts);
  }, [posts]);

  const filterPostsByCategory = (category) => {
    console.log("Selected Category:", category);
    setSelectedCategory(category);

    if (category === "") {
      // Display all posts when no category is selected
      setFilteredPosts(posts);
      console.log(posts);
      setShowAllPosts(true);
    } else {
      // Filter posts by the selected category
      const filtered = posts.filter((post) => post.category === category);
      console.log("Filtered Posts:", filtered);
      setFilteredPosts(filtered);
      setShowAllPosts(false);
    }
  };

  const resetFilter = () => {
    setSelectedCategory("");
    setFilteredPosts(posts);
  };
  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div>
      <BannerSlider />
      <div className="main-container">
        <div className="filter">
          <label>Filter by Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => filterPostsByCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <button className="filter-button" onClick={resetFilter}>
            default
          </button>
        </div>
        <h2>
          {showAllPosts ? "All Templates" : `Templates - ${selectedCategory}`}
        </h2>
        <div id="projects-grid">
          {filteredPosts
            .filter(
              (post) =>
                post.media &&
                post.media.startsWith(
                  "https://firebasestorage.googleapis.com/"
                ) &&
                post.media.endsWith(".mp4")
            )
            .map((post) => (
              <div className="project-div comment" key={post.id}>
                <Link to={`/post/${post.id}`} className="linkStyle">
                  <h3 className="title">{post.title}</h3>
                  {post.media && (
                    <video className="img" controls>
                      <source src={post.media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Link>

                <div className="engagement">
                  <div className="engagement-button">
                    <LikeButton postId={post.id} />
                  </div>
                  <div className="engagement-button">
                    <CommentButton postId={post.id} />
                  </div>
                  <div className="engagement-button">
                    <RetweetButton postId={post.id} />
                  </div>
                  <div className="engagement-button">
                    <ShareButton
                      postTitle={post.title}
                      postCategory={post.category}
                      postImageURL={post.postImage}
                      postPath={`/#/post/${post.id}`}
                    />
                  </div>
                </div>
                <br />

                <div className="project-body">
                  <p>Price: N{post.price}</p>
                  <p>Time Interval: {post.duration} Days</p>
                  <a href={post.link}></a>
                  <Chat />
                </div>
              </div>
            ))}
        </div>
        <PostButton isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
      </div>
    </div>
  );
};

export default VideoFeeds;
