import React, { useState, useEffect } from "react";
import {
  auth,
  collection,
  onSnapshot,
  firestore,
} from "../components//utils/Firebase"; // Adjust this import based on your Firebase setup
import { FaBell } from "react-icons/fa";

const NotificationIcon = () => {
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [lastCheckedTimestamp, setLastCheckedTimestamp] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          const userId = user.uid;
          const userRef = collection(
            firestore,
            "users",
            userId,
            "notifications"
          );

          const unsubscribeNotifications = onSnapshot(userRef, (snapshot) => {
            const notificationsArray = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => b.timestamp - a.timestamp);

            const latestTimestamp = notificationsArray[0]?.timestamp || 0;

            // Check if there are new notifications after the lastCheckedTimestamp
            if (latestTimestamp > lastCheckedTimestamp) {
              setHasNewNotification(true);
            } else {
              setHasNewNotification(false);
            }

            // Update lastCheckedTimestamp after processing notifications
            setLastCheckedTimestamp(latestTimestamp);

            // Set loading to false after the initial check
            setLoading(false);
          });

          return () => {
            unsubscribeNotifications();
          };
        }
      },
      [lastCheckedTimestamp]
    );

    // Additional cleanup to reset lastCheckedTimestamp when user logs out
    return () => {
      setLastCheckedTimestamp(0);
    };
  }, []);

  if (loading) {
    // Display loading state or nothing until the initial check is completed
    return null;
  }

  return (
    <span style={{ position: "relative" }}>
      <FaBell />

      {/* Overlay the red barge conditionally */}
      {hasNewNotification && (
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "red",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
          }}
        />
      )}
    </span>
  );
};

export default NotificationIcon;
