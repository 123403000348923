// PostImage.jsx
import React, { useState } from "react";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, arrayUnion, serverTimestamp } from "firebase/firestore";
import firebaseApp from "../components/utils/Firebase";

const FileUpload = ({ onUpload }) => {
  const firebase = firebaseApp;
  const [image, setImage] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Upload the file to Firebase Storage
      const storage = getStorage(firebase);
      const storageReference = storageRef(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);

      try {
        await uploadTask;

        // Get the download URL after the upload is complete
        const fileUrl = await getDownloadURL(storageReference);
        console.log("File URL", fileUrl);

        // Pass the file URL back to the parent component
        onUpload(fileUrl);

        setImage(fileUrl);
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} accept="*/*" />
      {image && <p>File uploaded: {image}</p>}
    </div>
  );
};

export default FileUpload;
