import React from "react";
import { Link } from "react-router-dom";
import "./workshop.css";
import { FiDownloadCloud, FiSend } from "react-icons/fi";
import { FaCloudDownloadAlt, FaCloudUploadAlt } from "react-icons/fa";

const Workshop = ({ isAdmin }) => {
  return (
    <div className="workshop-container">
      <div className="workshop-card">
        <h2>Receive Work</h2>
        <div
          style={{
            width: "100%",
            fontSize: "8rem",
            background: "rgb(131,58,180)",
            background:
              "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
          }}
        >
          <FaCloudDownloadAlt />
        </div>
        <Link to="/receive-work">
          <button className="workshop-button">Receive Work</button>
        </Link>
      </div>

      {isAdmin && (
        <div className="workshop-card">
          <h2>Send Work</h2>
          <div
            style={{
              width: "100%",
              fontSize: "8rem",
              background: "rgb(131,58,180)",
              background:
                "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
            }}
          >
            <FaCloudUploadAlt />
          </div>
          <Link to="/send-work">
            <button className="workshop-button">Send Work</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Workshop;
