import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { firestore, collection, addDoc, auth } from "../utils/Firebase";
import { FaRecycle } from "react-icons/fa";
//import { AuthContext } from "../context/AuthContext";

const RetweetButton = ({ postId }) => {
  //const { currentUser } = useContext(AuthContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setCurrentUser(authUser);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleRetweet = async () => {
    try {
      const retweetsCollection = collection(firestore, "retweets");
      await addDoc(retweetsCollection, {
        postId,
        user: currentUser.displayName || "Anonymous",
      });

      setShowConfirmation(true);
      // You can customize the confirmation logic or handle it differently
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } catch (error) {
      console.error("Error retweeting:", error.message);
    }
  };

  return (
    <div className="retweet-button-container">
      <span onClick={handleRetweet}>
        <FaRecycle />
      </span>
      {showConfirmation && (
        <div className="confirmation-message">Retweeted!</div>
      )}
    </div>
  );
};

export default RetweetButton;
