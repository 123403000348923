import React from "react";
import googleAdsImage from "../img/googleads.png";
import admobImage from "../img/ads.png";
import facebookAdsImage from "../img/fb.png";
import instagramAdsImage from "../img/instagram.png";
import twitterAdsImage from "../img/x.jpg";
import linkedInAdsImage from "../img/LinkedIn_icon.svg.png";
import youTubeAdsImage from "../img/youtube.png";
import boostSeoImage from "../img/seo.jpeg";

const AdsMarketing = () => {
  const marketingTools = [
    {
      name: "Google Ads",
      image: googleAdsImage,
      description:
        "Reach your audience through Google's vast advertising network.",
    },
    {
      name: "AdMob",
      image: admobImage,
      description: "Monetize your mobile app through in-app advertisements.",
    },
    {
      name: "Facebook Ads",
      image: facebookAdsImage,
      description:
        "Advertise your business on the world's largest social media platform.",
    },
    {
      name: "Instagram Ads",
      image: instagramAdsImage,
      description:
        "Engage visually with your audience through Instagram's image and video ads.",
    },
    {
      name: "Twitter Ads",
      image: twitterAdsImage,
      description:
        "Promote your brand through targeted tweets and campaigns on Twitter.",
    },
    {
      name: "LinkedIn Ads",
      image: linkedInAdsImage,
      description:
        "Connect with professionals and businesses through LinkedIn advertising.",
    },
    {
      name: "YouTube Ads",
      image: youTubeAdsImage,
      description:
        "Leverage video content to reach a global audience through YouTube advertising.",
    },
    {
      name: "Boost SEO Rankings",
      image: boostSeoImage,
      description:
        "Improve your website's search engine rankings for increased visibility.",
    },
    // Add more marketing tools as needed
  ];

  return (
    <div>
      <h2>Ads and Marketing Services</h2>
      <p>
        Explore our comprehensive suite of advertising and marketing solutions
        to boost your brand's visibility and reach.
      </p>
      <div className="marketing-grid">
        {marketingTools.map((tool, index) => (
          <div className="marketing-card" key={index}>
            <img src={tool.image} alt={tool.name} className="marketing-image" />
            <h3>{tool.name}</h3>
            <p>{tool.description}</p>
          </div>
        ))}
        {/* Add additional marketing tools in the same format */}
      </div>
    </div>
  );
};

export default AdsMarketing;
