import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  firestore,
  collection,
  orderBy,
  getDocs,
  query,
  authStateChanged,
} from "../components/utils/Firebase";
import BlogButton from "./BlogButton";

import "./Blog.css";
import ShareButton from "../components/utils/ShareButton";
import LikeButton from "../components/utils/LikeButton";
import CommentButton from "../components/utils/CommentButton";
import { FiLoader } from "react-icons/fi";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "harryjeffersonswagking@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchAllBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(firestore, "blogs"), orderBy("createdAt", "desc"))
        );

        const blogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoading(false);
        console.log("Fetched blogs:", blogsData);

        setBlogs(blogsData);
      } catch (error) {
        console.error("Error fetching all blog posts: ", error);
      }
    };

    fetchAllBlogs();
  }, []);

  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div className="blog-container">
      <div>
        {blogs.map((blog) => (
          <div className="blog-card" key={blog.id}>
            <Link className="linkStyle" to={`/blog/${blog.id}`}>
              <div className="blog-card-img">
                <img src={blog.image} />
                <h3>{blog.title}</h3>
              </div>
            </Link>

            {/* Add more details if needed */}
          </div>
        ))}
      </div>
      <BlogButton isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
    </div>
  );
};

export default AllBlogs;
