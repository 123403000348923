import React, { useState, useEffect, useContext } from "react";
import {
  firestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  auth,
  doc,
  getDoc,
} from "../utils/Firebase";

import { onAuthStateChanged } from "firebase/auth";
import { FiMessageCircle } from "react-icons/fi";

const Comment = ({ postId }) => {
  // const { currentUser } = useContext(AuthContext); // Assuming you have an AuthContext for managing authentication
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);
        await fetchUserData(authUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUserData = async (user) => {
    const userDocRef = doc(firestore, "users", user.uid);

    try {
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setUserData(userData);
        // Now userData contains the user data
        console.log("User Data:", userData);
      } else {
        console.error("User data not found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  fetchUserData();

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const commentsCollection = collection(firestore, "comments");
        const postCommentsQuery = query(
          commentsCollection,
          where("postId", "==", postId)
        );
        const snapshot = await getDocs(postCommentsQuery);

        const commentsData = snapshot.docs.map((doc) => doc.data());
        setComments(commentsData);
      } catch (error) {
        console.error("Error fetching comments:", error.message);
      }
    };

    fetchComments();
  }, [postId]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAddComment = async () => {
    try {
      if (!user) {
        // User is not authenticated, show a message or navigate to sign-in page
        alert("Please sign in to add a comment.");
        // Alternatively, navigate to your sign-in page
        // navigate("/signin");
        return;
      }
      const commentsCollection = collection(firestore, "comments");
      const newCommentRef = await addDoc(commentsCollection, {
        postId,
        text: comment,
        user: userData.username || "Anonymous", // Use authenticated user's display name
      });

      setComments([
        ...comments,
        {
          id: newCommentRef.id,
          postId,
          text: comment,
          user: userData.username,
        },
      ]);
      setComment("");
    } catch (error) {
      console.error("Error adding comment:", error.message);
    }
  };

  return (
    <div className="comment-body">
      <h1>
        Comment <FiMessageCircle />
      </h1>
      <hr />
      {comments.map((c) => (
        <div style={{ margin: "10px" }} key={c.id}>
          <strong>{c.user}:</strong> {c.text}
        </div>
      ))}
      <br />
      <div className="comment-section">
        <textarea
          value={comment}
          onChange={handleCommentChange}
          placeholder="Add a comment..."
        />
        <button onClick={handleAddComment}>Add Comment</button>
      </div>
    </div>
  );
};

export default Comment;
