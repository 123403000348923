import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextEditor from "./TextEditor";
import {
  firestore,
  collection,
  doc,
  getDoc,
} from "../components/utils/Firebase";

import "./Blog.css";
import ShareButton from "../components/utils/ShareButton";
import LikeButton from "../components/utils/LikeButton";
import CommentButton from "../components/utils/CommentButton";
import Comment from "../components/utils/Comment";

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState("");
  const [blogId, setBlogId] = useState("");

  useEffect(() => {
    // Fetch the individual blog post
    const fetchBlog = async () => {
      try {
        const docRef = doc(collection(firestore, "blogs"), id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          setBlog({
            id: docSnapshot.id,
            ...docSnapshot.data(),
          });
          setBlogId(blog.id);
        } else {
          // Handle case when the blog post is not found
          console.log("Blog post not found");
        }
      } catch (error) {
        console.error("Error fetching blog post: ", error);
      }
    };

    fetchBlog();
  }, [id]);

  return (
    <div>
      {blog ? (
        <div className="blog-card-detail">
          <h2>{blog.title}</h2>
          <img src={blog.image} />
          <p style={{ padding: "1rem" }}>
            Author: {blog.author || "Unknown Author"}{" "}
            <span>
              at{" "}
              {blog.createdAt &&
                new Date(blog.createdAt.toDate()).toLocaleDateString()}
            </span>
          </p>
          <div
            className="blog-card-detail-body"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
          <hr />
          <div>
            <div className="engagement-button">
              <LikeButton postId={blog.id} />
            </div>

            <div className="engagement-button">
              <ShareButton
                postTitle={blog.title}
                postCategory={blog.authorName}
                postImageURL={blog.image}
                postPath={`/#/blog/${blog.id}`}
              />
            </div>
          </div>
          <div>
            <Comment postId={blog} />
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BlogDetails;
