import React, { useState, useEffect } from "react";
import { NavLink, Navigate, useNavigate, Link } from "react-router-dom";
import {
  FiActivity,
  FiBriefcase,
  FiHome,
  FiMenu,
  FiPaperclip,
  FiSpeaker,
  FiTool,
  FiXCircle,
} from "react-icons/fi";
import { auth } from "../components/utils/Firebase"; // Import your Firebase authentication instance
import "./menu.css";
import logo from "../img/logo.png";
import {
  FaAward,
  FaBell,
  FaBriefcase,
  FaGraduationCap,
  FaHome,
  FaNewspaper,
  FaQuestionCircle,
  FaTools,
  FaUser,
} from "react-icons/fa";
import NotificationIcon from "../notification/NotificationIcons";

const Menu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const toggleClass = () => {
    setisMenu((prevState) => !prevState);
    setResponsiveclose((prevState) => !prevState);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };
  const handleLogin = () => {
    navigate("/Log-in");
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  }

  return (
    <header className="header__middle">
      <div className="container">
        <div className="row">
          <div className="header__middle__menus">
            <nav className="main-nav">
              {/* Responsive Menu Button */}
              {isResponsiveclose ? (
                <span
                  className="menubar__button"
                  style={{ position: "fixed", left: "0" }}
                  onClick={toggleClass}
                >
                  {" "}
                  <FiXCircle />{" "}
                </span>
              ) : (
                <span className="menubar__button" onClick={toggleClass}>
                  {" "}
                  <FiMenu />{" "}
                </span>
              )}

              <ul className={boxClass.join(" ")}>
                <Link to={`/`} className="logo-link">
                  <img
                    src={logo} // Replace with the actual path to your logo
                    alt="Logo"
                    className="logo3"
                    onClick={toggleClass}
                  />
                </Link>
                <h2 style={{ textAlign: "center" }}>Alliance Explorer</h2>
                <p style={{ textAlign: "center" }}>All Things Digital</p>
                <hr />
                <ul className="menu-list">
                  <li className="menu-item">
                    <NavLink
                      exact
                      activeclassname="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      {" "}
                      <FaHome /> Home{" "}
                    </NavLink>
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/about`}
                    >
                      {" "}
                      <FaGraduationCap /> About{" "}
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/tools`}
                    >
                      {" "}
                      <FaTools /> Dev Tools
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/notification`}
                    >
                      <NotificationIcon /> Notification
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/marketing`}
                    >
                      {" "}
                      <FiSpeaker /> Marketing
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/blog`}
                    >
                      <FaNewspaper /> News
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/chat`}
                    >
                      {" "}
                      <FaQuestionCircle /> Support
                    </NavLink>{" "}
                  </li>
                  <li className="menu-item ">
                    <NavLink
                      onClick={toggleClass}
                      activeclassname="is-active"
                      to={`/profile`}
                    >
                      <FaUser /> profile
                    </NavLink>{" "}
                  </li>
                  <li className="login-button" onClick={toggleClass}>
                    {/* Login/Logout Button */}
                    {user ? (
                      <button className="login-button" onClick={handleLogout}>
                        Logout
                      </button>
                    ) : (
                      <button className="login-button" onClick={handleLogin}>
                        Login
                      </button>
                    )}
                  </li>
                </ul>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Menu;
