import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../utils/Auth";
import "./Signin.css";
import BackButton from "../utils/BackButton";
import {
  firestore,
  collection,
  addDoc,
  where,
  getDoc,
  getDocs,
  query,
  setDoc,
  doc,
} from "../utils/Firebase";

// ... (previous imports and code)

const SignUpComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 1000); // Change 1000 to your desired range
  };

  const generateUsernameFromEmail = (email) => {
    // Extract the part before '@' from the email and add random numbers
    const usernameFromEmail = email.split("@")[0];
    const randomNumber = generateRandomNumber();
    return `${usernameFromEmail}${randomNumber}`;
  };

  const checkEmailExists = async () => {
    try {
      const usersCollection = collection(firestore, "users");
      const emailQuery = query(usersCollection, where("email", "==", email));
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        setErrorMessage("Email already exists. Please use a different email.");
        return true; // Email already exists
      }

      return false; // Email is unique
    } catch (error) {
      console.error("Error checking email existence:", error.message);
      return true; // Assume email exists on error
    }
  };

  const handleSignUp = async () => {
    try {
      // Check if email already exists
      const emailExists = await checkEmailExists();

      if (emailExists) {
        return; // Do not proceed with sign-up
      }

      // Check if passwords match
      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        return;
      }

      // Sign up the user
      const user = await signUp(email, password);

      // Generate username from email and add random numbers
      const generatedUsername = generateUsernameFromEmail(email);

      // Push user details to Firestore with user UID as the document ID
      const usersCollection = collection(firestore, "users");
      const userDocRef = doc(usersCollection, user.uid); // Explicitly set the user UID as the document ID
      const userData = {
        email: email,
        username: generatedUsername,
        // Assume you have a "name" field in your Firestore user document
        // Replace with the actual field in your Firestore document
      };
      await setDoc(userDocRef, userData);

      const notificationsCollection = collection(userDocRef, "notifications");
      const welcomeNotification = {
        message: `Welcome, ${userData.username}! Its good to have you aboard, Enjoy your experience as we offer you stunning service and free monlthly graphic designs!`,
        timestamp: new Date().getTime(),
      };
      await addDoc(notificationsCollection, welcomeNotification);

      await addDoc(notificationsCollection, welcomeNotification);

      alert("Signed up successfully!");
      navigate("/");
    } catch (error) {
      console.error("Sign-up failed:", error.message);
    }
  };

  return (
    <div className="div">
      <div className="sign-in-form">
        <BackButton />
        <h2 className="h2">Sign Up</h2>
        <input
          className="input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="button1" onClick={handleSignUp}>
          Sign Up
        </button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default SignUpComponent;
