import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Chat = () => {
  const handleChatClick = () => {
    // Replace '1234567890' with your actual WhatsApp number
    const phoneNumber = "+2349034361658";

    // Create a WhatsApp link with the phone number
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp chat in a new tab
    window.open(whatsappLink, "_blank");
  };

  const containerStyle = {
    textAlign: "right",
    marginTop: "20px",
    fontFamily: "Arial, sans-serif",
    paddingRight: "10px",
  };

  const chatTextStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#075e54", // WhatsApp green color

    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <p>
        <span onClick={handleChatClick} style={chatTextStyle}>
          <FaWhatsapp
            style={{
              verticalAlign: "text-bottom",
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />{" "}
          Chat
        </span>
      </p>
    </div>
  );
};

export default Chat;
