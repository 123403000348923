import React from "react";
import { FaBriefcase, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function ChatButton({ isAuthenticated, isAdmin = false }) {
  const navigate = useNavigate();

  const ButtonOnClicked = () => {
    navigate("/chat");
  };

  return (
    <div>
      <button onClick={ButtonOnClicked} className="arrow">
        <FiMail />
      </button>
    </div>
  );
}

export default ChatButton;
