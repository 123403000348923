import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./MeetOurTeam.css"; // Import your custom styles

const MeetOurTeam = () => {
  const [openMember, setOpenMember] = useState(null);

  const teamMembers = [
    {
      name: "Harry Francis",
      role: "Lead Developer",
      expertise: "Front-end and Back-end Development",
      bio: "Harry is the lead developer at Alliance Explorer. With a passion for coding and problem-solving, he brings expertise in front-end and back-end development. Harry is dedicated to delivering high-quality solutions and ensuring the success of the team.",
      social: {
        facebook: "https://www.facebook.com/harrycodebase",
        instagram: "https://www.instagram.com/harrycodebase/",
        twitter: "https://twitter.com/harrycodebase",
        linkedin: "https://www.linkedin.com/in/harryfrancis/",
      },
      image: "harry.png", // Replace with the actual image file name
    },
    {
      name: "Awaghor Godswill",
      role: "Graphic Designer and Logo Creator",
      expertise: "Graphic Design, Logo Design",
      bio: "Best is a skilled graphic designer and logo creator. With a keen eye for aesthetics, he transforms ideas into visually appealing designs. Best is passionate about creating unique and memorable brand identities.",
      social: {
        facebook: "https://www.facebook.com/profile.php?id=61552992526347",
        linkedin: "https://www.linkedin.com/in/godswill-awaghor/",
      },
      image: "best.png",
    },
    {
      name: "Emily Wilson",
      role: "Digital Marketing Manager",
      expertise: "Digital Marketing, SEO, SEM",
      bio: "Emily is our digital marketing expert. With a deep understanding of online marketing strategies, She develops and implements effective campaigns to enhance brand visibility and drive engagement. Emily is committed to achieving marketing goals.",
      social: {
        facebook: "https://www.facebook.com/davidmiller",
        twitter: "https://twitter.com/davidmiller",
        linkedin: "https://www.linkedin.com/in/davidmiller/",
      },
      image: "emily.png",
    },

    {
      name: "Alex Turner",
      role: "UI/UX Designer",
      expertise: "UI/UX Design, Prototyping",
      bio: "Alex is our talented UI/UX designer, specializing in creating intuitive and user-friendly interfaces. With a focus on user experience, Alex enhances the overall design of our projects to ensure optimal usability and satisfaction.",
      social: {
        facebook: "https://www.facebook.com/alexturner",
        instagram: "https://www.instagram.com/alexturner/",
        linkedin: "https://www.linkedin.com/in/alexturner/",
      },
      image: "alex.png",
    },
    // Add more team members as needed
  ];

  const toggleDetails = (index) => {
    setOpenMember((prevOpenMember) =>
      prevOpenMember === index ? null : index
    );
  };

  return (
    <div className="meet-our-team">
      <h2>Meet Our Team</h2>
      <p className="about-p">
        Welcome to Alliance Explorer, where innovation meets expertise! Our
        dedicated team, led by the visionary Harry Francis, is a dynamic force
        in the world of development. We are passionate about turning ideas into
        reality and crafting digital solutions that transcend expectations. With
        a harmonious blend of creativity, technical prowess, and a commitment to
        excellence, the Alliance Dev Team stands at the forefront of innovation.
        Together, we embark on a journey to bring your projects to life,
        delivering cutting-edge solutions that redefine the possibilities of
        technology. Join us as we explore new horizons, empower businesses, and
        shape the digital future
      </p>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`team-member ${
              index === teamMembers.length - 1 ? "float-left" : ""
            }`}
          >
            <div className="member-header" onClick={() => toggleDetails(index)}>
              <img
                src={require(`../img/${member.image}`)}
                alt={member.name}
                className="team-member-image"
              />

              <h3>{member.name}</h3>
              <div className="social-icons">
                {member.social && member.social.facebook && (
                  <a
                    href={member.social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} className="icon" />
                  </a>
                )}
                {member.social && member.social.instagram && (
                  <a
                    href={member.social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} className="icon" />
                  </a>
                )}
                {member.social && member.social.twitter && (
                  <a
                    href={member.social.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} className="icon" />
                  </a>
                )}
                {member.social && member.social.linkedin && (
                  <a
                    href={member.social.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} className="icon" />
                  </a>
                )}
              </div>
            </div>
            {openMember === index && (
              <div
                className={`member-details ${
                  openMember === index ? "slide-down" : ""
                }`}
              >
                <p className="role">{member.role}</p>
                <p>{member.expertise}</p>
                <p>{member.bio}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MeetOurTeam;
