import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  firestore,
  collection,
  addDoc,
  where,
  query,
  getDocs,
  auth,
} from "../utils/Firebase";
import { FaComment, FaCommentAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
//import { AuthContext } from "../context/AuthContext";

const CommentButton = ({ postId }) => {
  // const { currentUser } = useContext(AuthContext);
  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setCurrentUser(authUser);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleAddComment = async () => {
    try {
      if (!currentUser) {
        // User is not authenticated, show a message or navigate to sign-in page
        alert("Please sign in to add a comment.");
        // Alternatively, navigate to your sign-in page
        // navigate("/signin");
        return;
      }
      const commentsCollection = collection(firestore, "comments");
      const newCommentRef = await addDoc(commentsCollection, {
        postId,
        text: comment,
        user: currentUser.displayName || "Anonymous",
      });

      setComment("");
      setShowInput(false);

      // Navigate to the post detail page after successfully adding the comment
      navigate(`/post/${postId}`);
    } catch (error) {
      console.error("Error adding comment:", error.message);
    }
  };

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  return (
    <div className="comment-button-container">
      <span onClick={toggleInput}>
        <FaCommentAlt />
      </span>
      {showInput && (
        <div className="comment-feed">
          <textarea
            style={{
              height: "50px",
              flex: "2",
              marginTop: "10px",
              background: "black",
              color: "white",
            }}
            value={comment}
            onChange={handleCommentChange}
            placeholder="Add a comment..."
          />
          <button
            style={{
              height: "50px",
              padding: "auto",
              flex: "1",
              margin: "0 5px",
              marginTop: "16px",
              background: "rgb(99, 4, 4)",
              border: "1px solid black",
              borderRadius: "20px",
            }}
            onClick={handleAddComment}
          >
            <FiSend />
          </button>
        </div>
      )}
    </div>
  );
};

export default CommentButton;
