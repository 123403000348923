import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  auth,
  firestore,
} from "../components/utils/Firebase"; // Adjust the import path
import { FiLoader } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import "./NotificationComponent.css"; // Import the CSS file

const UserNotificationComponent = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!auth.currentUser) {
        // If user is not authenticated, show a message and set state to navigate
        setShowLoginMessage(true);
        return;
      }

      const userId = auth.currentUser.uid;
      try {
        const userRef = collection(
          firestore,
          "users",
          userId,
          "notifications" // Adjust the subcollection name if needed
        );
        const notificationsCollection = await getDocs(userRef);

        const notificationsArray = notificationsCollection.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => b.timestamp - a.timestamp); // Sort by timestamp, newest first

        setNotifications(notificationsArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [userId, navigate]);

  useEffect(() => {
    // Use a timeout to show the message for a few seconds before redirecting
    if (showLoginMessage) {
      const timeoutId = setTimeout(() => {
        setShowLoginMessage(false);
        navigate("/Log-in");
      }, 3000); // Show the message for 3 seconds
      return () => clearTimeout(timeoutId);
    }
  }, [showLoginMessage, navigate]);

  return (
    <div>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Notifications
      </h2>
      {loading ? (
        <FiLoader />
      ) : notifications.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            padding: "0",
            margin: "0",
            fontSize: "1.5rem",
          }}
        >
          No notifications yet
        </p>
      ) : (
        <div className="notification-container">
          <ul>
            {notifications.map((notification) => (
              <li className="notification-item" key={notification.id}>
                <p
                  style={{
                    textAlign: "left",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {" "}
                  {notification.message}
                </p>
                <h6
                  style={{
                    textAlign: "right",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {notification.timestamp
                    ? notification.timestamp.toDate().toLocaleDateString()
                    : "N/A"}
                </h6>
              </li>
            ))}
          </ul>
        </div>
      )}
      {showLoginMessage && (
        <p
          style={{
            textAlign: "center",
            padding: "0 1rem",
            margin: "0",
            fontSize: "1.3rem",
          }}
        >
          Please log in to view notifications.
        </p>
      )}
    </div>
  );
};

export default UserNotificationComponent;
