// AdminNotificationComponent.js

import React, { useState, useEffect } from "react";
import {
  collection,
  getDoc,
  getDocs,
  firestore,
  addDoc,
} from "../components/utils/Firebase";
import { serverTimestamp } from "firebase/firestore";

import "./NotificationComponent.css";

const AdminNotificationComponent = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [newNotification, setNewNotification] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, "users");
        const snapshot = await getDocs(usersCollection);

        const usersArray = snapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setUsers(usersArray);
        console.log(usersArray);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSendNotification = async () => {
    console.log("Sending notification...");
    if (selectedUser && newNotification.trim() !== "") {
      try {
        const userNotificationsRef = collection(
          firestore,
          "users",
          selectedUser,
          "notifications"
        );
        await addDoc(userNotificationsRef, {
          message: newNotification,
          timestamp: serverTimestamp(),
        });
        setNewNotification("");
        alert("Notification sent successfully.");
      } catch (error) {
        console.error("Error sending notification:", error);
      }
    }
  };

  return (
    <div className="notification-container">
      <h2> Notifications Manager</h2>
      <div>
        <label htmlFor="userSelect">Select User:</label>
        <select
          id="userSelect"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="">Select User</option>
          {users.map((user) => (
            <option key={user.uid} value={user.uid}>
              {user.username} {/* Adjust based on your user data structure */}
            </option>
          ))}
        </select>
      </div>
      <div className="notification-container-input">
        <textarea
          className="notification-container-input"
          value={newNotification}
          onChange={(e) => setNewNotification(e.target.value)}
          placeholder="Type your notification here..."
          rows="3"
        />
      </div>
      <div>
        <button onClick={handleSendNotification}>Send Notification</button>
      </div>
    </div>
  );
};

export default AdminNotificationComponent;
