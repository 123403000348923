import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { auth, firestore } from "../components/utils/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

import "./UserProfile.css";
import { FiLoader } from "react-icons/fi";

const UserProfiledata = () => {
  const { userId } = useParams();

  const [posts, setPosts] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [logoutMessage, setLogoutMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mediaType, setMediaType] = useState("image");
  const [imagePosts, setImagePosts] = useState([]);
  const [videoPosts, setVideoPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDocRef = doc(firestore, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          setProfileData(userDocSnapshot.data());
        }

        // Fetch posts created by the user
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", userId)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const userPosts = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(userPosts);

        // Separate posts into images and videos
        const imagePosts = userPosts.filter(
          (post) => post.mediaType === "image"
        );
        const videoPosts = userPosts.filter(
          (post) => post.mediaType === "video"
        );

        setImagePosts(imagePosts);
        setVideoPosts(videoPosts);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const toggleMediaType = () => {
    setMediaType((prevType) => (prevType === "image" ? "video" : "image"));
  };

  return (
    <div className="user-profile-container">
      {loading ? (
        <FiLoader />
      ) : userId ? (
        <>
          <div className="profile-card">
            <div className="profile-card-img">
              <img src={profileData.image} alt={profileData.username} />
            </div>
            <div className="profile-card-details">
              <h2>{profileData.username}</h2>
              <p>Email: {profileData.email}</p>
              <p>Bio: {profileData.bio}</p>
              <p>Specialty: {profileData.specialty}</p>
              {/* You may need to add the UpdateButton component */}
            </div>
          </div>
          {posts.length > 0 ? (
            <div>
              <h3>My works</h3>

              <ul>
                <button onClick={toggleMediaType}>
                  Toggle {mediaType === "image" ? "Video" : "Image"}
                </button>
                <div className="user-posts-grid">
                  {posts.map((post) => (
                    <div key={post.id} className="user-post-item">
                      {post.mediaType === "image" ? (
                        <img className="media" src={post.media} alt="Post" />
                      ) : post.mediaType === "video" ? (
                        <video className="media" controls>
                          <source src={post.media} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p>Invalid media type</p>
                      )}
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          ) : (
            <p>No posts yet.</p>
          )}
        </>
      ) : (
        <div className="login-section">
          <p></p>
        </div>
      )}
    </div>
  );
};

export default UserProfiledata;
