import React from "react";
import { FaShare } from "react-icons/fa";

const ShareButton = ({ postTitle, postCategory, postImageURL, postPath }) => {
  const postUrl = `${window.location.origin}${postPath}`;
  const handleShare = () => {
    if (navigator.share) {
      const file = new File([postImageURL], "image.jpg", {
        type: "image/jpeg",
      });

      navigator
        .share({
          title: postTitle,
          text: `Check out this ${postCategory} post: ${postTitle}`,
          url: postUrl,
          files: [file],
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // Fallback for browsers that do not support Web Share API
      console.log("Web Share API not supported");
      // Implement your custom share functionality here
      alert(`Share this post: ${postTitle} (${postCategory}) - ${postUrl}`);
    }
  };

  return (
    <span>
      <FaShare onClick={handleShare} />
    </span>
  );
};

export default ShareButton;
