import React, { useState } from "react";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { FiImage, FiLoader } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import firebaseApp from "./Firebase";

const PostMedia = ({ onMediaUpload }) => {
  const firebase = firebaseApp;
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMediaChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setLoading(true);

      // Check if the file is an image
      if (file.type.startsWith("image")) {
        const options = {
          maxSizeMB: 0.1, // Maximum size in megabytes after compression
          maxWidthOrHeight: 800, // Maximum width or height after compression
          useWebWorker: true, // Use web workers for faster compression
        };

        try {
          // Use the browser-image-compression library to compress the image
          const compressedFile = await imageCompression(file, options);

          // Upload and display the compressed image
          await uploadAndDisplayMedia(compressedFile, "image");
        } catch (error) {
          console.error("Error compressing or uploading image: ", error);
          setLoading(false);
        }
      } else {
        // Unsupported file type
        setLoading(false);
        console.error("Unsupported file type");
      }
    }
  };

  const uploadAndDisplayMedia = async (compressedFile, type) => {
    const storage = getStorage(firebase);
    const storageReference = storageRef(
      storage,
      `media/${compressedFile.name}`
    );
    const uploadTask = uploadBytesResumable(storageReference, compressedFile);

    try {
      await uploadTask;

      const mediaUrl = await getDownloadURL(storageReference);
      console.log("Media URL", mediaUrl);

      onMediaUpload(mediaUrl);
      setMedia(mediaUrl);
    } catch (error) {
      console.error("Error uploading media: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="post-media-container">
      <label htmlFor="mediaUpload" className="media-upload-label">
        <input
          type="file"
          accept="image/*"
          id="mediaUpload"
          onChange={handleMediaChange}
        />
        {loading && <FiLoader className="loader" />}
        {media && <FiImage className="media-icon" />}
      </label>
      {media && <img src={media} alt="Media" className="uploaded-media" />}
    </div>
  );
};

export default PostMedia;
