import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../img/Whatsapp.jpg";
import img2 from "../../img/instagram.jpg";
import img3 from "../../img/Google.jpg";
import img4 from "../../img/WordPress_blue_logo.svg.png";
import img5 from "../../img/Facebook.jpg";
import img6 from "../../img/twitter.jpg";
import "./banner.css";

const BannerSlider = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(2);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    // Initial setup
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide navigation arrows
    slidesToShow: slidesToShow,
  };
  return (
    <div className="slider">
      <Slider {...settings} className="banner-slider">
        <div className="slider-item">
          <img src={img1} alt="Banner 1" />
        </div>
        <div className="slider-item">
          <img src={img2} alt="Banner 2" />
        </div>
        <div className="slider-item">
          <img src={img3} alt="Banner 3" />
        </div>
        <div className="slider-item">
          <img src={img4} alt="Banner 2" />
        </div>
        <div className="slider-item">
          <img src={img5} alt="Banner 3" />
        </div>
      </Slider>
    </div>
  );
};

export default BannerSlider;
