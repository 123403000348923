import React from "react";
import { FaBriefcase } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function PostButton({ isAdmin }) {
  const navigate = useNavigate();

  const ButtonOnClicked = () => {
    navigate("/create");
  };

  return (
    <div>
      {isAdmin && (
        <button onClick={ButtonOnClicked} className="arrow">
          <FaBriefcase />
        </button>
      )}
    </div>
  );
}

export default PostButton;
