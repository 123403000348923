import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import { auth, authStateChanged } from "./components/utils/Firebase";
import { initializeDatabase } from "./components/utils/Firebase";
import SignUpComponent from "./components/auth/SignUp";
import SignInComponent from "./components/auth/SignIn";
import ForgotPasswordComponent from "./components/auth/ResetPassword";
import UserProfile from "./profile/UserProfile";

import Footer from "./components/Footer";
import Welcome from "./components/auth/Welcome";
import BottomNavBar from "./navbar/Navbar";
import CreatePost from "./posts/CreatePost";
import Feeds from "./feeds/Feeds";
import PostDetails from "./feeds/FeedDescription";
import Navbarmenu from "./navbar/Navbarmenu";
import UpdateProfile from "./profile/UpdateProfile";
import SearchComponent from "./components/search/Search";
import AdminRoute from "./AdminInterface";
import Notification from "./components/Notification";
import PostButton from "./components/utils/PostButton";
import loader from "./img/token2.png";
import Workshop from "./workspace/Workshop";
import SendWork from "./workspace/SendJob";
import ReceiveJob from "./workspace/RecieveJob";
import AllBlogs from "./blog/BlogPost";
import CreateBlog from "./blog/CreateBlog";
import BlogDetails from "./blog/BlogDetails";
import VideoFeeds from "./feeds/VideoFeed";
import About from "./pages/About";
import AdsMarketing from "./pages/Marketing";
import ToolsUsed from "./pages/Tools";
import UserChatComponent from "./messages/UserChat";

import UserChatContainer from "./messages/ChatContainer";
import BusinessCard from "./business/BusinessCard";
import AdminNotificationComponent from "./notification/AdminNotification";
import UserNotificationComponent from "./notification/UserNotification";
import UserProfiledata from "./profile/userProfledata";

function App() {
  const [user, setUser] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUserUid, setCurrentUserUid] = useState(null);

  const supportUserUid = "wx7ZE1RGKHfVr9kDEmESupIGgQx1"; // New state for admin check
  //const adminId = "wx7ZE1RGKHfVr9kDEmESupIGgQx1";
  useEffect(() => {
    // Initialize the database structure
    initializeDatabase();

    // Check online status
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Initialize the database structure
    initializeDatabase();
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
      setCurrentUserUid(user.uid);
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise
      setIsAdmin(user && user.email === "harryjeffersonswagking@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  return (
    <HashRouter>
      <div>
        <Navbarmenu />
        <div className="main-container">
          {!onlineStatus && (
            <div>Offline. Please check your internet connection.</div>
          )}
          <Routes>
            <Route exact path="/" element={<Feeds />} />
            <Route path="/home" element={<Feeds />} />{" "}
            <Route path="/about" element={<About />} />{" "}
            <Route path="/marketing" element={<AdsMarketing />} />{" "}
            <Route path="/tools" element={<ToolsUsed />} />{" "}
            <Route path="/home" element={<Feeds />} />
            <Route path="/profile" element={<UserProfile user={user} />} />
            <Route path="/user/:userId" element={<UserProfiledata />} />
            <Route path="/video" element={<VideoFeeds />} />
            <Route
              path="/achievement"
              element={<BusinessCard isAdmin={isAdmin} />}
            />
            <Route
              path="/post/:postId"
              element={<PostDetails isAdmin={isAdmin} />}
            />
            <Route
              path="/chat"
              element={
                isAdmin ? (
                  <UserChatContainer />
                ) : (
                  <UserChatComponent
                    currentUserUid={currentUserUid}
                    supportUserUidId={supportUserUid}
                  />
                )
              }
            />
            <Route
              path="/notification"
              element={
                isAdmin ? (
                  <AdminNotificationComponent />
                ) : (
                  <UserNotificationComponent />
                )
              }
            />
            <Route
              path="/feed"
              element={user ? <Feeds userId={user.uid} /> : <Navigate to="/" />}
            />
            <Route
              path="/create"
              element={<AdminRoute element={<CreatePost />} user={user} />}
            />
            <Route
              path="/create-blog"
              element={<AdminRoute element={<CreateBlog />} user={user} />}
            />
            <Route path="/blog/:latestBlogs.id" element={<BlogDetails />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/blog" element={<AllBlogs isAdmin={isAdmin} />} />
            <Route path="/workshop" element={<Workshop isAdmin={isAdmin} />} />
            <Route path="/send-work" element={<SendWork />} />
            <Route path="/receive-work" element={<ReceiveJob />} />
            <Route
              path="/user-profile"
              element={user ? <UserProfile user={user} /> : <Navigate to="/" />}
            />
            {!user && (
              <>
                <Route path="/Sign-up" element={<SignUpComponent />} />
                <Route path="/Log-in" element={<Welcome />} />
                <Route path="/sign-in" element={<SignInComponent />} />
                <Route
                  path="/Forget-password"
                  element={<ForgotPasswordComponent />}
                />
              </>
            )}
            {user && (
              <>
                <Route path="/create" element={<CreatePost />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/Update-profile" element={<UpdateProfile />} />
              </>
            )}
          </Routes>

          <BottomNavBar isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
