import React, { useState } from "react";
import { firestore, auth } from "../components/utils/Firebase";
import { addDoc, collection } from "firebase/firestore";
import "./CreatePost.css"; // Import your custom styles
import PostImage from "../components/utils/PostImage";
import { useNavigate } from "react-router-dom";

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [media, setMedia] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");
  const [mediaType, setMediaType] = useState(""); // Added mediaType state

  const navigate = useNavigate();
  const categories = [
    "Website",
    "Web Apps",
    "Logos",
    "Flyers",
    "Posters",
    "ID",
    "Business Card",
    "Social Ads",
  ]; // List of categories

  const handleSubmit = async () => {
    if (
      !title ||
      !description ||
      !price ||
      !duration ||
      !media ||
      !category ||
      !mediaType
    ) {
      alert(
        "Title, description, Price, duration, media, mediaType, and category are required fields."
      );
      return;
    }

    const postData = {
      title,
      description,
      price,
      duration,
      category,
      media,
      link,
      mediaType,
      // Include mediaType in postData
    };

    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated.");
        return;
      }

      // Generate a random likeCount between 500 and 990
      const randomLikeCount = Math.floor(Math.random() * (990 - 100 + 1)) + 100;

      // Add post data to Firestore
      const postData = {
        title,
        price,
        duration,
        description,
        category,
        media,
        likeCount: randomLikeCount,
        link,
        mediaType,
        userId: user.uid, // Include the userId
      };

      // Add a document to the "posts" collection
      await addDoc(collection(firestore, "posts"), postData);

      // Clear form after submission
      setTitle("");
      setDescription("");
      setMedia("");
      setPrice("");
      setDuration("");
      setCategory("");
      setMediaType("");
      setLink("");
      alert("Work created successfully");
      navigate("/home");
    } catch (error) {
      console.error("Error submitting post:", error.message);
    }
  };

  const handleMediaUpload = (mediaUrl) => {
    setMedia(mediaUrl);
    console.log("Media URL", mediaUrl);
  };

  return (
    <div className="main-container create-post-container">
      <h2>Create a New Post</h2>
      <PostImage onMediaUpload={handleMediaUpload} required />
      <label>Title:</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        placeholder="enter title"
        className="input-field"
      />
      <div className="post-tag">
        <div className="post-info">
          <label>Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="input-field"
          />
        </div>
        <div className="post-info">
          <label>Duration:</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
            className="input-field"
          />
        </div>
      </div>

      <label>Description:</label>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
        className="input-field"
      />

      <label>Category:</label>
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        required
        className="input-field"
      >
        <option value="">Select a category</option>
        {categories.map((cat) => (
          <option key={cat} value={cat}>
            {cat}
          </option>
        ))}
      </select>

      <label>Link (optional):</label>
      <input
        type="text"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        className="input-field"
      />

      <label>Media Type:</label>
      <select
        value={mediaType}
        onChange={(e) => setMediaType(e.target.value)}
        required
        className="input-field"
      >
        <option value="">Select media type</option>
        <option value="image">Image</option>
        <option value="video">Video</option>
      </select>

      <button onClick={handleSubmit} className="submit-button">
        Post
      </button>
    </div>
  );
};

export default CreatePost;
