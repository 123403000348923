import React, { useState, useEffect } from "react";
import {
  auth,
  firestore,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
} from "../utils/Firebase";
import { FiHeart } from "react-icons/fi";
// Make sure to replace 'your-app-name' with your actual Firebase app name
import { getFirestore, arrayUnion, arrayRemove } from "firebase/firestore";
import { useSpring, animated } from "react-spring";

const LikeButton = ({ postId }) => {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [animatedCount, setAnimatedCount] = useState(0);

  const countSpring = useSpring({
    number: animatedCount,
    from: { number: 0 },
  });

  useEffect(() => {
    const fetchLikeData = async () => {
      try {
        // Check if there is a saved liked state in local storage
        const initialLikedState = JSON.parse(
          localStorage.getItem(`liked_${postId}`)
        );

        // If there is, set the initial liked state
        if (initialLikedState !== null) {
          setLiked(initialLikedState);
        }
        const docRef = doc(firestore, "posts", postId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setLikeCount(data.likeCount || 0);
          const initialLikedState =
            data.likes && auth.currentUser
              ? data.likes.includes(auth.currentUser.uid)
              : false;
          setLiked(initialLikedState);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching like data:", error);
      }
    };
    const unsubscribe = onSnapshot(
      doc(firestore, "posts", postId),
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setLikeCount(data.likeCount || 0);
          setLiked(
            data.likes && auth.currentUser
              ? data.likes.includes(auth.currentUser.uid)
              : false
          );
        }
      }
    );

    fetchLikeData();

    return () => unsubscribe();
  }, [postId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedCount(likeCount);
    }, 1000);

    return () => clearInterval(interval);
  }, [likeCount]);

  const handleLikeClick = async () => {
    if (!auth.currentUser) {
      alert("Please sign in to like the post.");
      return;
    }

    try {
      const docRef = doc(firestore, "posts", postId);

      if (liked) {
        await updateDoc(docRef, {
          likeCount: likeCount - 1,
          likes: arrayRemove(auth.currentUser.uid),
        });
        setLikeCount(likeCount);
      } else {
        await updateDoc(docRef, {
          likeCount: likeCount + 1,
          likes: arrayUnion(auth.currentUser.uid),
        });
      }

      setLiked(!liked);
    } catch (error) {
      console.error("Error updating like status:", error);
    }
  };

  return (
    <span>
      <FiHeart
        onClick={handleLikeClick}
        style={{
          color: liked ? "red" : "white",
          cursor: "pointer",
        }}
      />
      <animated.span>
        {countSpring.number.interpolate((val) => Math.round(val))}
      </animated.span>
    </span>
  );
};

export default LikeButton;
